<template>
  <div class="">
    <div class="col-md-12">
      <div class="mb-1">
        <b-col class="row alert alert-success p-2 col-md-12">
          <b-avatar variant="success" size="20" class="shadow mb-1 mr-1">
            <feather-icon size="28" icon="CheckIcon" />
          </b-avatar>
          <h4 class="text-success"  v-if="slider && slider != '100%'">
            {{ $t("congrats_invoice_fraction_successfully_bought") }}
          </h4>
          <h4 class="text-success" v-else>
            {{ $t("congrats_invoice_successfully_bought") }}
          </h4>
        </b-col>
      </div>

      <div class="mb-4" v-if="slider && slider != '100%'">
        <b-col class="row alert alert-primary p-2 col-md-12">
          <b-avatar variant="light-primary" size="30" class="shadow mr-1">
            <feather-icon size="20" icon="AlertTriangleIcon" />
          </b-avatar>
          <h4 class="text-primary">
            {{ $t("only_fraction_was_bought_message") }}
          </h4>
        </b-col>
      </div>
      <br />
      <b-row
        v-for="(item, index) in invoiceData"
        :key="index"
        class="mb-1 mt-1"
      >
        <b-col xl="3" lg="3" md="4">
          <h4 class="font-weight-bolder">
            {{ $t(item.text) }}
          </h4>
        </b-col>
        <b-col>
          <h4>
            {{ item.value }}
          </h4>
        </b-col>
      </b-row>
      <br /><br /><br /><br /><br />
      <b-row>
        <h5 class="col-md-12 mt-2 text-primary">
          {{ $t("archive_invoice_in_documents_message") }}
        </h5>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  props: ["invoiceData", "slider"],
  data() {
    return {};
  },
  watch: {
    invoiceData(newVal, oldVal) {

    },
  },
  created() {


  },
};
</script>